const brandJump = () => {
  const configStore = useConfigStore()
  const localeMap = {
    [LANGEN]: 'en-001',
    [LANGCN]: 'zh-cn',
    [LANGTW]: 'zh-tw',
    [LANGRU]: 'ru',
  }
  const localePath = {
    [LANGEN]: '/categories/8272646968079',
    [LANGCN]: '/categories/8272646968079',
    [LANGTW]: '/categories/8272646968079',
    [LANGRU]: '/categories/8272646968079',
  }
  const url = `https://bifinance.zendesk.com/hc/${localeMap[configStore.locale]}${localePath[configStore.locale]}`
  window.open(url)
}

export default brandJump
