<!--
 * @Author: ken yang
 * @Date: 2023-02-28 16:27:41
 * @LastEditTime: 2023-04-24 14:35:15
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/empty/ListEmpty.vue
-->
<template>
  <el-empty class="ListEmpty" :image-size="Number(size)">
    <template #image>
      <div class="no-list" />
    </template>
    <template #description>
      <span class="label c-gray c-cs-9b">{{ $t('public.emptyData') }}</span>
    </template>
  </el-empty>
</template>

<script setup>
const emit = defineEmits()
const configStore = useConfigStore()

const props = defineProps({
  size: {
    default: 68
  },
  name: {
    default: 'popular'
  }
})

const width = $computed(() => props.size + 'px')
const height = $computed(() => (props.size * 1.1764) + 'px')

onBeforeMount(() => {})
</script>

<style lang="scss">
@import '@/sass/var/index.scss';
.light,html{
  .ListEmpty{
    .no-list{
      background-image: url("image/empty/no-list.png");
      background-size: auto 100%;
      background-repeat: no-repeat;
      height: 68px;
    }
  }
}
.dark{
  .ListEmpty{
    .no-list{
      background-image: url("image/empty/no-list-dark.webp");
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.el-dialog{
  .ListEmpty{
    .no-list{
      background-image: url("image/empty/no-list.png") !important;
      background-size:auto 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.ListEmpty{
  padding-top: 100px;
  .no-list{
    width: v-bind(width);
    height: v-bind(height);
  }
  .el-empty__description{
    margin-top: 10px !important;
    line-height: normal;
  }
}
</style>
