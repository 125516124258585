<!--
 * @Author: ken yang
 * @Date: 2022-09-02 16:54:48
 * @LastEditTime: 2023-09-05 15:58:04
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/App.vue
-->
<template>
  <el-scrollbar
    ref="scrollbar"
    wrap-class=" column"
    :height="height"
    :class="[bodyClass, configStore.tradeStyle,'min-W','flow-auto','position-r', 'app-scrollbar']"
    :always="true"
    :noresize="true"
  >
    <FlexWidget :always="isSmallScreen" class="small-box" :class="isSmallScreen ? 'W' : ''">
      <el-config-provider :locale="locale">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <Component :is="Component" class="app-router-view" />
          </keep-alive>
        </router-view>
      </el-config-provider>
    </FlexWidget>
  </el-scrollbar>
</template>

<script setup>
import { Column } from '@/layout/index.js'

const route = useRoute()
const userStore = useUserStore()
const quotesStore = useQuotesStore()
const configStore = useConfigStore()
const mapStore = useMapStore()
const mailStore = useMailStore()

configStore.getMonitorLogStatus()

globalThis.$route = route

const tradeStore = useTradeStore()

const scrollbar = $ref(null)
let locale = $ref()
let height = $ref(window.innerHeight)
let width = $ref(window.innerWidth)

let bodyClass = $ref()
const isSmallScreen = $ref(false)
const setBodyClass = () => {
  if (window.innerWidth > 1200) {
    bodyClass = 'min-W'
    // isSmallScreen = false
  } else {
    bodyClass = ''
    // isSmallScreen = true
  }
}

const FlexWidget = $computed(() => isSmallScreen ? ElScrollbar : Column)

const setLocale = async () => {
  const langMap = {
    [LANGCN]: import('element-plus/dist/locale/zh-cn.mjs'),
    [LANGEN]: import('element-plus/dist/locale/en.mjs'),
    [LANGTW]: import('element-plus/dist/locale/zh-tw.mjs'),
    [LANGRU]: import('element-plus/dist/locale/ru.mjs'),
  }
  locale = (await langMap[configStore.locale]).default
}

const jumpPaths = ['/setNewPassword', '/login']

// 登录以后自动跳转到home
const autoJump = () => {
  if (jumpPaths.includes(route.path) && userStore.token) {
    const redirectUrl = route.query?.redirect
    if (redirectUrl) {
      router.push(decodeURIComponent(redirectUrl))
    } else {
      router.push('/home')
    }
  }
}

useWinMesSetSize()

let HTMLDom

const getHTMLDom = () => {
  return isEmpty(HTMLDom) ? document.querySelector('.app-body') : HTMLDom
}

const setHtmlTheme = () => {
  const HTMLDom = getHTMLDom()
  const delKey = {
    'light': 'dark',
    'dark': 'light',
  }
  if (['/futures', '/trade', '/majroShareholder', '/christmas'].includes(route.path)) {
    HTMLDom?.classList.add(configStore.theme)
    HTMLDom?.classList.remove(delKey[configStore.theme])
    if (configStore.theme === 'dark') document.body.style.backgroundColor = '#1C1F2C'
    if (configStore.theme === 'light') document.body.style.backgroundColor = '#fff'
  } else {
    document.body.style.backgroundColor = '#fff'
    HTMLDom?.classList.add('light')
    HTMLDom?.classList.remove('dark')
  }
}

eventBus.on('onSetHtmlTheme', e => {
  setHtmlTheme()
})

eventBus.on('onResize', e => {
  height = window.innerHeight
  width = window.innerWidth
  setBodyClass()
  scrollbar.update()
})

watch(
  () => route.path,
  (path) => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    setHtmlTheme()
  }
)

watch(
  () => configStore.locale,
  (path) => {
    setLocale()
    mailStore.getList()
    mailStore.getUnreadCount()
  }
)

watch(
  () => configStore.locale,
  (newValue, oldValue) => {
    const HTMLDom = getHTMLDom()
    HTMLDom?.classList.add(`app-language-${newValue}`)
    HTMLDom?.classList.remove(`app-language-${oldValue}`)
  }
)

watch(() => route.path, (path) => {
  scrollbar?.setScrollTop(0)
  if (['/trade', '/futures', '/registerUpLand', '/user/register'].includes(path) || !userStore.token) {
    zE && zE('messenger', 'hide')
  } else if (path == '/bft') {
    document.title = $t('bft.pageTitle')
  } else if (path == '/registerUpLand') {
    document.title = $t('user.registerUpLandTitle')
  } else {
    document.title = 'BiFinance'
    zE && zE('messenger', 'show')
  }
  autoJump()
}, {
  immediate: true
})

watch(
  () => userStore.token,
  async () => {
    if (userStore.token) {
      userStore.logined()
    } else {
      mapStore.getAreaList()
      quotesStore.getTickers()
    }

    autoJump()
  }, {
    immediate: true
  }
)

eventBus.on('onAppSetScrollTop', () => scrollbar.setScrollTop(0))
eventBus.on('onAppSetScroll', (v) => scrollbar.setScrollTop(v))

// BiFinance
onBeforeMount(async () => {
  // localStorage.phoneAreaCode = localStorage.phoneAreaCode ?? '+86'
  setLocale()
  setBodyClass()

  console.log('当前环境', import.meta.env)
})

// -----------将语言包导出成excel---------------
// import android from '@/language/zh/android.js'
// import downloadExcelLang from '@/utils/globalFunc/downloadExcelLang.js'
// downloadExcelLang(android)

onMounted(() => {
  setHtmlTheme()
  width = window.innerWidth
})

</script>

<style lang="scss">
@use "sass:math";
@use "sass:map";
@use '@/sass/index.scss' as *;

body::-webkit-scrollbar {
 display: none;
}
body {
    -ms-overflow-style: none;
}
html,
body,
#app {
  margin: 0;
  padding: 0;
  word-break: break-word;
}
iframe {
  border: 0px;
}
span,div{
  word-break: break-word;
}
.group-2JyOhh7Z- {
  background-color: transparent !important;
}

.hide-zendesk{
  iframe[title="用于启动消息传送窗口的按钮，正在对话"]{
    display: none !important;
  }
  div[role="presentation"]{
    display: none !important;
  }
}

.app-scrollbar{
  >.is-horizontal{
    // background-color:red !important;
    display: none !important;
  }
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-default);
}
</style>
